.feature .page-header .img {
  max-width: 189px;
}
.feature__block {
  margin: 0;
  padding: 33px 15px 45px;
}
.feature__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.feature__anchor {
  margin: 30px auto -15px;
}
.feature__lead {
  margin-bottom: 26px;
}
.feature__list {
  margin: 15px 0;
}
.feature__img1 {
  margin-top: 40px;
}
.feature__ttl {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.feature__img2 {
  margin: 20px auto 0;
  padding: 0 5px;
}
.feature__note {
  margin: 30px auto 0;
}
.feature__note li {
  padding-left: 28px;
  font-size: 13px;
  line-height: 1.92;
  position: relative;
}
.feature__note li span {
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (min-width: 768px) {
  .feature .page-header .img {
    max-width: 360px;
  }
  .feature__block {
    padding: 80px 15px 95px;
  }
  .feature .cont1 .flexBox {
    display: flex;
    justify-content: space-between;
  }
  .feature .cont1 .flexItem.text {
    width: 52%;
  }
  .feature .cont1 .flexItem.img {
    width: 41%;
  }
  .feature__lead {
    margin-top: -5px;
    margin-bottom: 33px;
    max-width: 560px;
  }
  .feature__list {
    margin: 26px 0;
  }
  .feature__img1 {
    margin-top: 0;
  }
  .feature__ttl {
    margin-top: 60px;
    font-size: 24px;
  }
  .feature__img2 {
    margin-top: 40px;
    padding: 0 5px;
    max-width: 944px;
  }
  .feature__note {
    margin-top: 50px;
    max-width: 843px;
  }
  .feature__note li {
    padding-left: 36px;
  }
}