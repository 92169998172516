.feature{

	.page-header{

		.img{
			max-width: 189px;
		}
	}

	&__block{
		margin: 0;
		padding: 33px 15px 45px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 1100px;
		}
	}

	&__anchor{
		margin: 30px auto -15px;
	}

	//-------------------------------------
	&__lead{
		margin-bottom: 26px;
	}

	//-------------------------------------
	&__list{
		margin: 15px 0;
	}

	&__img1{
		margin-top: 40px;
	}

	//-------------------------------------
	&__ttl{
		margin-top: 40px;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
	}

	//-------------------------------------
	&__img2{
		margin: 20px auto 0;
		padding: 0 5px;
	}

	//-------------------------------------
	&__note{
		margin: 30px auto 0;

		li{
			padding-left: 28px;
			font-size: 13px;
			line-height: 1.92;
			position: relative;

			span{
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

}
@media screen and (min-width:768px){
	.feature{

		.page-header{

			.img{
				max-width: 360px;
			}
		}

		&__block{
			padding: 80px 15px 95px;
		}

		//-------------------------------------
		.cont1{

			.flexBox{
				display: flex;
				justify-content: space-between;
			}

			.flexItem{

				&.text{
					width: 52%;
				}

				&.img{
					width: 41%;
				}
			}
		}

		//-------------------------------------
		&__lead{
			margin-top: -5px;
			margin-bottom: 33px;
			max-width: 560px;
		}

		//-------------------------------------
		&__list{
			margin: 26px 0;
		}

		//-------------------------------------
		&__img1{
			margin-top: 0;
		}

		//-------------------------------------
		&__ttl{
			margin-top: 60px;
			font-size: 24px;
		}

		//-------------------------------------
		&__img2{
			margin-top: 40px;
			padding: 0 5px;
			max-width: 944px;
		}

		//-------------------------------------
		&__note{
			margin-top: 50px;
			max-width: 843px;

			li{
				padding-left: 36px;
			}
		}

	}
}